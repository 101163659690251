import { ReactNode } from 'react'

import { IStatusAnalystTeamProps } from '@/components/Tag/StatusAnalystTeam/types'
import {
  IStatusCardTeamProps,
  status,
} from '@/components/Tag/StatusCardTeam/types'
import { IStatusVolumeTeamProps } from '@/components/Tag/StatusVolumeTeam/types'
import { TeamMember } from '@/types/team.types'

export interface ITeamProps {
  id: string
  title: string
  description: string
  date: string
  supervisor: ITeamUser
  status: IStatusCardTeamProps
  children?: ReactNode
  onDelete?: (id: string) => void
}

export type ITeamUser = {
  label: string
  value: string
}

export interface ITeamDetailsProps {
  teamId: string
}

export interface ITeamAnalystsDetailProps {
  id: string
  name: string
  cpf: string
  email: string
  date: string
  status: IStatusAnalystTeamProps
}
export interface ITeamVolumeDetailProps {
  id: string
  name: string
  editora: string
  colecao: string
  analyst: ITeamUser[]
  versao: string
  exemplar: string
  formato: string
  status: IStatusVolumeTeamProps
}

export const ITeamStatusMapping: Record<string, status> = {
  ativa: 'active',
  Inativo: 'inactive',
  Concluido: 'finished',
  Esperando_Objetos: 'waiting_objects',
  'ESPERANDO MEMBROS': 'waiting_members',
  PENDENTE: 'pending',
  'ESPERANDO OBRAS': 'waiting_works',
  'EM ANDAMENTO': 'active',
}
export const ITeamAnalystStatusMapping: Record<string, status> = {
  INATIVO: 'inactive',
  ATIVO: 'active',
}

export interface ITeamAnalystProps {
  analysts: TeamMember[]
}
