import React, { useState } from 'react'
import { FaTimes, FaCheck } from 'react-icons/fa'

import {
  Switch as ChakraSwitch,
  useStyleConfig,
  Box,
  Text,
  Spinner,
} from '@chakra-ui/react'

import { CustomSwitchProps } from './types'

const Switch: React.FC<CustomSwitchProps> = ({
  label,
  disabled = false,
  isLoading = false,
  onChange,
  ...props
}) => {
  const [isChecked, setIsChecked] = useState(false)
  const styles = useStyleConfig('Switch', {})

  const handleToggle = () => {
    setIsChecked(!isChecked)
    onChange(!isChecked)
  }

  return (
    <Box display="flex" alignItems="center" gap={4}>
      <Text
        color={disabled ? 'brand.neutral.light_1' : 'brand.neutral.dark_2'}
        fontSize="sm"
      >
        {label}
      </Text>
      <Box position="relative">
        <ChakraSwitch
          isChecked={isChecked}
          onChange={handleToggle}
          isDisabled={disabled}
          size="lg"
          sx={{
            ...styles,
            '.chakra-switch__track': {
              bg: 'white',
              border: '1px solid',
              borderColor: 'brand.neutral.light_1',
              borderRadius: 'full',
              _disabled: {
                borderColor: '#e2e2e2',
                cursor: 'not-allowed',
              },
            },
            '.chakra-switch__thumb': {
              width: '24px',
              height: '24px',
              bg: isChecked ? '#8C93A4' : 'gray.300',
              transition: 'all 0.2s',
              _disabled: {
                bg: '#e2e2e2',
              },
            },
          }}
          {...props}
        />
        <Box
          position="absolute"
          top="50%"
          left={isChecked ? 'calc(100% - 1.35rem)' : '15%'}
          transform="translateY(-50%)"
          color="white"
          fontSize="sm"
        >
          {isChecked ? <FaCheck /> : <FaTimes />}
        </Box>
      </Box>
      {isLoading && <Spinner size="sm" color="brand.primary.dark_2" />}
    </Box>
  )
}

export default Switch
