import http from '@/api/http'

import {
  TDeleteFormParams,
  TGetFormsResponse,
  TGetFormsParams,
  TPostFormParams,
  TPostFormResponse,
  TApproveFormParams,
  TApproveFormResponse,
  TGetFormParams,
  TGetFormResponse,
  TPatchFormParams,
  TPatchFormResponse,
  TGetFieldValuesResponse,
} from './types'

export const getForms = async (filters: TGetFormsParams) => {
  const response = await http.get<TGetFormsResponse>('/forms/form/', {
    params: filters,
  })

  return response.data
}

export const getForm = async ({ coForm }: TGetFormParams) => {
  const response = await http.get<TGetFormResponse>(`/forms/form/${coForm}/`)

  return response.data
}

export const deleteForm = async ({ coForm }: TDeleteFormParams) => {
  const response = await http.delete(`/forms/form/${coForm}/`)

  return response.data
}

export const postForm = async (data: TPostFormParams) => {
  const response = await http.post<TPostFormResponse>('/forms/form/', data)

  return response.data
}

export const approveForm = async (data: TApproveFormParams) => {
  const response = await http.post<TApproveFormResponse>(
    '/forms/form/approve/',
    data
  )

  return response.data
}

export const updateForm = async (data: TPatchFormParams) => {
  const response = await http.put<TPatchFormResponse>(
    `/forms/form/${data.coForm}/`,
    data
  )

  return response.data
}

export const getFieldValues = async () => {
  const response = await http.get<TGetFieldValuesResponse>(
    '/forms/form/field_values/'
  )

  return response.data
}
