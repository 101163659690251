import { AxiosError } from 'axios'

import { UseMutationOptions, useMutation } from '@tanstack/react-query'

import { Error } from '@/types/common.types'

import { deleteForm, postForm, updateForm, approveForm } from './services'
import {
  TDeleteFormResponse,
  TDeleteFormParams,
  TPostFormParams,
  TPostFormResponse,
  TApproveFormParams,
  TApproveFormResponse,
  TPatchFormParams,
  TPatchFormResponse,
} from './types'

export const useMutationPostForm = (
  options?: UseMutationOptions<
    TPostFormResponse,
    AxiosError<Error>,
    TPostFormParams
  >
) => {
  return useMutation(postForm, options)
}

export const useMutationDeleteForm = (
  options?: UseMutationOptions<
    TDeleteFormResponse,
    AxiosError<Error>,
    TDeleteFormParams
  >
) => {
  return useMutation(deleteForm, options)
}

export const useMutationApproveForm = (
  options?: UseMutationOptions<
    TApproveFormResponse,
    AxiosError<Error>,
    TApproveFormParams
  >
) => {
  return useMutation(approveForm, options)
}

export const useMutationPatchForm = (
  options?: UseMutationOptions<
    TPatchFormResponse,
    AxiosError<Error>,
    TPatchFormParams
  >
) => {
  return useMutation(updateForm, options)
}
