import React, { useState, useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'

import {
  Box,
  Flex,
  Image,
  Hide,
  Progress,
  useToast,
  useColorModeValue,
  Text,
} from '@chakra-ui/react'

import { useMutationPostAuthGovBr } from '@/api/auth/mutations'
import { useQueryAuthGovBrGetUrl } from '@/api/auth/queries'
import Kid from '@/assets/img/background/kid.png'
import LogoFNDE from '@/assets/img/fnde/logo/logo-fnde-w.svg'
import WhiteLogoSARE from '@/assets/img/sare/logo/logo-sare-b-nd-m-BB.svg'
import S from '@/common/Templates/TemplateLogin/styles'
import { getErrorDetails } from '@/utils/error'

const Login: React.FC = () => {
  const toast = useToast()
  const navigate = useNavigate()
  const [searchParams, setSearchParams] = useSearchParams()

  const isDevEnvironment = process.env.REACT_APP_ENVIRONMENT === 'dev'

  const [isLoggingIn, setIsLoggingIn] = useState(false)

  const { mutate: postAuthGovBr } = useMutationPostAuthGovBr({
    onSuccess: data => {
      localStorage.setItem('access-token', data.access)
      localStorage.setItem('refresh-token', data.refresh)

      if (data.firstAccess) {
        navigate('login/primeiro-acesso')

        return
      }

      navigate('/')
    },
    onError: error => {
      const statusCode = error.response?.status

      if (statusCode === 401) {
        navigate('/login/acesso-negado')

        return
      }

      toast({
        status: 'error',
        title: 'Ops! Ocorreu um erro',
        description: getErrorDetails(error),
        isClosable: true,
      })

      searchParams.delete('code')
      searchParams.delete('state')

      setSearchParams(searchParams)

      setIsLoggingIn(false)
    },
  })

  const {
    isFetching: isFetchingAuthGovBrUrl,
    isRefetching: isRefetchingAuthGovBrUrl,
    refetch: refetchAuthGovBrUrl,
  } = useQueryAuthGovBrGetUrl({
    enabled: false,
    onSuccess: url => {
      toast({
        status: 'success',
        title: 'Redirecionando para o gov.br',
        description: 'Aguarde...',
        isClosable: true,
      })

      window.location.href = url
    },
    onError: error => {
      toast({
        status: 'error',
        title: 'Erro ao redirecionar para o gov.br',
        description: getErrorDetails(error),
        isClosable: true,
      })
    },
  })

  const handleLogin = () => {
    refetchAuthGovBrUrl()
  }

  const handleLoginDev = () => {
    navigate('/login/desenvolvedor')
  }

  useEffect(() => {
    const code = searchParams.get('code')
    const state = searchParams.get('state')

    if (code && state) {
      postAuthGovBr({ code, state })

      setIsLoggingIn(true)
    }
  }, [])

  return (
    <Flex h="100vh">
      <Hide below="lg">
        <Flex flex={1} bg="#23415B" direction="column" h="full">
          <Flex flex={1} alignItems="flex-end" justify="center">
            <Box display="grid" gap={6} pb={6}>
              <Image src={LogoFNDE} h="40px" />
              <Box>
                <Text fontSize="5xl" fontWeight="medium" color="#FFFFFF">
                  Transformando vidas <br /> por meio da educação.
                </Text>
              </Box>
            </Box>
          </Flex>
          <Flex
            flex={1}
            alignItems="flex-end"
            justify="center"
            maxH={{ lg: '55%', xl: '65%' }}
          >
            <Image src={Kid} maxH="full" />
          </Flex>
        </Flex>
      </Hide>
      <Flex
        flex={1}
        bg="brand.highlight.dark"
        justify="center"
        align="center"
        direction="column"
        gap={16}
      >
        <Image src={WhiteLogoSARE} h="120px" />
        {isLoggingIn ? (
          <Progress size="sm" width="40%" borderRadius="1rem" isIndeterminate />
        ) : (
          <Flex
            direction="column"
            align="center"
            justify="center"
            wrap="wrap"
            gap="1rem"
            w="24rem"
          >
            <S.MainButton
              w="full"
              variant={useColorModeValue('solid', 'outline')}
              border={useColorModeValue('none', '1px solid #FFFF00')}
              color={useColorModeValue('white', '#FFFF00')}
              bg={useColorModeValue('brand.primary.dark_1', 'transparent')}
              _hover={useColorModeValue(
                { bg: 'brand.primary.dark_2', color: 'white' },
                { bg: '#FFFF0099', color: 'white' }
              )}
              isLoading={isFetchingAuthGovBrUrl || isRefetchingAuthGovBrUrl}
              onClick={handleLogin}
              {...(isDevEnvironment ? { flexGrow: 1 } : {})}
            >
              Entrar com gov.br
            </S.MainButton>

            {isDevEnvironment ? (
              <S.MainButton
                w="full"
                variant={useColorModeValue('solid', 'outline')}
                border={useColorModeValue('none', '1px solid #FFFF00')}
                color={useColorModeValue('white', '#FF0000')}
                bg={useColorModeValue('#C63637', 'transparent')}
                _hover={useColorModeValue(
                  { bg: '#C63637EB', color: 'white' },
                  { bg: '#FF000099', color: 'white' }
                )}
                onClick={handleLoginDev}
                flexGrow={1}
              >
                Entrar como desenvolvedor
              </S.MainButton>
            ) : null}
          </Flex>
        )}
      </Flex>
    </Flex>
  )
}

export default Login
