import { AxiosError } from 'axios'

import {
  TPreRegistrationError,
  PreRegistrationErrors,
} from '@/api/user/management/types'
import { Error } from '@/types/common.types'

const defaultErrorMsg = 'Por favor, tente novamente mais tarde.'

/**
 * Extracts the first error detail from API response.
 *
 * @param {AxiosError<Error>} error - The error object returned by Axios.
 * @returns {string} - The detail of the first error or a default error message.
 */
const getFirstErrorDetail = (error: AxiosError<Error>): string => {
  try {
    const errorDetail = error.response?.data.errors[0]?.detail
    return errorDetail ?? defaultErrorMsg
  } catch {
    return defaultErrorMsg
  }
}

/**
 * Retrieves a user-friendly error message.
 *
 * @param {AxiosError<Error>} error - The error object returned by Axios.
 * @returns {string} - A user-friendly error message.
 */
export function getErrorDetails(error: AxiosError<Error>): string {
  if (error.response) {
    return getFirstErrorDetail(error)
  }

  if (error.request) {
    return defaultErrorMsg
  }

  return defaultErrorMsg
}

/**
 * Returns the first relevant pre-registration error description from an Axios error.
 * Checks these error types in order: createdUsers, duplicatedUsers, emailedUsers, errorUsers, invalidCpf.
 *
 * @param error - Axios error with pre-registration error data
 * @returns Error description if valid error found, otherwise default message
 */
export function getErrorDetailsPreRegistration(
  error: AxiosError<TPreRegistrationError>
): string {
  const errorTypes: Array<PreRegistrationErrors> = [
    'createdUsers',
    'duplicatedUsers',
    'emailedUsers',
    'errorUsers',
    'invalidCpf',
  ]

  const errorData = error.response?.data ?? ({} as TPreRegistrationError)

  const matchedError = errorTypes.find(errorType => {
    const detail = errorData[errorType]
    return detail?.values?.length > 0
  })

  return matchedError ? errorData[matchedError].description : defaultErrorMsg
}
