import axios, { InternalAxiosRequestConfig, AxiosResponse } from 'axios'
import applyCaseMiddleware from 'axios-case-converter'

import { Error } from '@/types/common.types'

const http = applyCaseMiddleware(
  axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    withCredentials: true,
  })
)

const PUBLIC_ROUTES = [
  '/auth/govbr/',
  '/auth/govbr/get-url/',
  '/auth/local/login/',
]

http.interceptors.request.use((request: InternalAxiosRequestConfig) => {
  const accessToken = localStorage.getItem('access-token')

  const isPublicRoute = PUBLIC_ROUTES.some(route =>
    request.url?.includes(route)
  )

  if (!isPublicRoute && accessToken) {
    request.headers.Authorization = `Bearer ${accessToken}`
  }

  return request
})

http.interceptors.response.use(
  (response: AxiosResponse) => {
    return response
  },
  error => {
    if (error.response) {
      const path = error.response.config.url
      const status = error.response.status

      if (status === 401 && !path?.includes('/login')) {
        const errorResponse = error.response.data as Error
        const errors = errorResponse.errors.map(error => error.code)

        if (errors.includes('not_authenticated')) {
          localStorage.removeItem('access-token')
          localStorage.removeItem('refresh-token')
          window.location.href = '/login'
        }
      }
    }

    return Promise.reject(error)
  }
)

export default http
