import React, { useMemo, useState } from 'react'
import { IoHelpCircle } from 'react-icons/io5'
import { useNavigate, useParams } from 'react-router-dom'

import { Text, Flex, Icon, Skeleton } from '@chakra-ui/react'

import CardsContainer from '@/components/Cards/Container/CardsContainer'
import { ICardComponentProps } from '@/components/Cards/Container/types'

import { QuestionGroup, IQuestionsListProps } from '../types'

export const QuestionsList = ({
  questions,
  isLoading,
}: IQuestionsListProps) => {
  const navigate = useNavigate()
  const [selectedQuestion, setSelectedQuestion] = useState<QuestionGroup>()
  const [selectedQuestionId, setSelectedQuestionId] = useState<string | null>(
    null
  )
  // const questions: Array<IQuestionsGroupData> = [
  //   {
  //     id: '1',
  //     name: 'Título da pergunta',
  //     description: 'Description 1',
  //     type: 'Tipo da resposta',
  //     dsStatus: 'active',
  //   },
  //   {
  //     id: '2',
  //     name: 'Título da pergunta',
  //     description: 'Description 2',
  //     type: 'Tipo da resposta',
  //     dsStatus: 'inactive',
  //   },
  //   {
  //     id: '3',
  //     name: 'Título da pergunta',
  //     description: 'Description 3',
  //     type: 'Tipo da resposta',
  //     dsStatus: 'active',
  //   },
  //   {
  //     id: '4',
  //     name: 'Título da pergunta',
  //     description: 'Description 4',
  //     type: 'Tipo da resposta',
  //     dsStatus: 'inactive',
  //   },
  // ]

  const questionsData: Array<ICardComponentProps> = useMemo(() => {
    return (questions || []).map(question => ({
      id: String(question?.id),
      title: question?.name,
      description: question?.description,
      status: {
        tag: question.dsStatus,
      } as ICardComponentProps['status'],
    }))
  }, [questions])

  const handleDeleteQuestion = (id: string) => {
    // TODO: Implement delete question
  }
  const handleEditQuestion = (id: string) => {
    //TODO: Implement edit question
  }

  return (
    <>
      <Skeleton isLoaded={!isLoading} minH="200px" borderRadius="xl">
        <CardsContainer
          initialCards={questionsData}
          iconUrl={true}
          idText={true}
          statusTag={true}
          onEdit={id => handleEditQuestion(id)}
          onCopy={id => setSelectedQuestionId(id)}
          onDelete={id => handleDeleteQuestion(id)}
        />
      </Skeleton>
    </>
  )
}
