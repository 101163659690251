import { UseMutationOptions, useMutation } from '@tanstack/react-query'

import { Error } from '@/types/common.types'

import { postPermissionsGroup, updatePermissionsGroup } from './services'
import {
  TCreatePermissionsGroupParams,
  TCreatePermissionsGroupResponse,
  TUpdatePermissionsGroupParams,
  TUpdatePermissionsGroupResponse,
} from './types'

export const useMutationCreatePermissionsGroup = (
  options?: UseMutationOptions<
    TCreatePermissionsGroupResponse,
    Error,
    TCreatePermissionsGroupParams
  >
) => {
  return useMutation(postPermissionsGroup, options)
}

export const useMutationUpdatePermissionsGroup = (
  options?: UseMutationOptions<
    TUpdatePermissionsGroupResponse,
    Error,
    TUpdatePermissionsGroupParams
  >
) => {
  return useMutation(updatePermissionsGroup, options)
}
