import { AxiosError } from 'axios'

import { QueryKey, useQuery, UseQueryOptions } from '@tanstack/react-query'

import { Error } from '@/types/common.types'

import { getObjects } from './services'
import { TGetObjectsParams, TGetObjectsResponse } from './types'

export const useQueryGetObjects = (
  filters: TGetObjectsParams,
  options?: UseQueryOptions<TGetObjectsResponse, AxiosError<Error>>
) => {
  const queryFunction = () => getObjects(filters)

  return useQuery<TGetObjectsResponse, AxiosError<Error>>(
    useQueryGetObjects.getFullQueryKey(filters),
    queryFunction,
    options
  )
}

useQueryGetObjects.getFullQueryKey = (filters: TGetObjectsParams): QueryKey => [
  'objects',
  filters,
]

useQueryGetObjects.queryKey = ['objects']
