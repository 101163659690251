import {
  QueryKey,
  useQueries,
  useQuery,
  UseQueryOptions,
} from '@tanstack/react-query'

import { getTeam, getTeams, getTeamsPaginated } from './services'
import {
  TGetTeamsPaginatedParams,
  TGetTeamsPaginatedResponse,
  TGetTeamsResponse,
  TGetTeamParams,
  TGetTeamResponse,
} from './types'

export const useQueryTeams = (options?: UseQueryOptions<TGetTeamsResponse>) => {
  const queryFunction = () => getTeams()

  return useQuery<TGetTeamsResponse>(
    useQueryTeams.getFullQueryKey(),
    queryFunction,
    options
  )
}

useQueryTeams.getFullQueryKey = (): QueryKey => ['teams']

useQueryTeams.queryKey = ['teams']

export const useQueryTeamsPaginated = (
  input: TGetTeamsPaginatedParams,
  options?: UseQueryOptions<TGetTeamsPaginatedResponse>
) => {
  const queryFunction = () => getTeamsPaginated(input)

  return useQuery<TGetTeamsPaginatedResponse>(
    useQueryTeamsPaginated.getFullQueryKey(input),
    queryFunction,
    options
  )
}

useQueryTeamsPaginated.getFullQueryKey = (
  input: TGetTeamsPaginatedParams
): QueryKey => ['teams_paginated', input]

useQueryTeamsPaginated.queryKey = (
  input: TGetTeamsPaginatedResponse
): QueryKey => ['teams_paginated', input]

export const useQueryTeam = (
  input: TGetTeamParams,
  options?: UseQueryOptions<TGetTeamResponse>
) => {
  const queryFunction = () => getTeam(input)

  return useQuery<TGetTeamResponse>(
    useQueryTeam.getFullQueryKey(),
    queryFunction,
    options
  )
}

useQueryTeam.getFullQueryKey = (): QueryKey => ['Team']

useQueryTeam.queryKey = ['Team']
