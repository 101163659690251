import React, { useMemo, useRef, useState } from 'react'
import { BsChevronLeft } from 'react-icons/bs'
import { FaPlusCircle, FaSave } from 'react-icons/fa'
import { useNavigate } from 'react-router-dom'

import { Form, Formik, FormikProps, FormikValues } from 'formik'
import * as yup from 'yup'

import {
  Flex,
  Button,
  Text,
  Stack,
  useToast,
  useDisclosure,
} from '@chakra-ui/react'

import { useMutationPostForm } from '@/api/forms/form/mutations'
import { useQueryFieldValues } from '@/api/forms/form/queries'
import { Select, Input } from '@/components/Forms'
import { Question } from '@/types/form.types'

import { ModalQuestion } from './AddQuestionModal'
import { EmptyQuestions } from './EmptyQuestion'
import { QuestionsList } from './QuestionsList'

const CreateForm = () => {
  const toast = useToast()
  const navigate = useNavigate()
  const [questions, setQuestions] = useState<Question[]>([])
  const formikRef = useRef<FormikProps<FormikValues>>(null)

  const {
    isOpen: isModalOpen,
    onOpen: onModalOpen,
    onClose: onCloseModal,
  } = useDisclosure()

  const { data: fieldValues, isLoading: isFieldValuesLoading } =
    useQueryFieldValues()

  const { mutate: registerForm, isLoading: isRegisteringForm } =
    useMutationPostForm({
      onSuccess: () => {
        formikRef.current?.resetForm()
        toast({
          title: 'Formulário cadastrado com sucesso!',
          status: 'success',
          duration: 3000,
          isClosable: true,
        })
        navigate('/formularios/')
      },
    })

  const objectsOptions = useMemo(() => {
    return (fieldValues?.objects || [])?.map(object => ({
      value: object.coObject,
      label: object.dsObject,
    }))
  }, [fieldValues])

  const stageOptions = useMemo(() => {
    return (fieldValues?.stages || [])?.map(stage => ({
      value: stage.coStage,
      label: stage.dsStage,
    }))
  }, [fieldValues])

  const volumeFormatOptions = useMemo(() => {
    return (fieldValues?.formats || [])?.map(format => ({
      value: format.coFormat,
      label: format.dsFormat,
    }))
  }, [fieldValues])

  const volumeVersionOptions = useMemo(() => {
    return (fieldValues?.versions || [])?.map(version => ({
      value: version,
      label: version,
    }))
  }, [fieldValues])

  const volumeExemplaryOptions = useMemo(() => {
    return (fieldValues?.exemplaries || [])?.map(exemplary => ({
      value: exemplary.coExemplary,
      label: exemplary.dsExemplary,
    }))
  }, [fieldValues])

  const handleAddQuestion = () => {
    onModalOpen()
  }

  const handleRegisterForm = () => {
    if (formikRef.current) {
      formikRef.current.handleSubmit()
    }
  }

  const handleSubmit = (values: FormikValues) => {
    const payload = {
      dsForm: values.dsForm,
      coObject: Number(values.coObject),
      coPhase: Number(values.coPhase),
      coFormat: Number(values.coFormat),
      dsVolumeVersion: values.dsVolumeVersion,
      coExemplary: Number(values.coExemplary),
      coUser: 1,
    }

    registerForm(payload)
  }

  return (
    <>
      <Stack p={5} boxShadow="md" borderRadius="base" gap={4}>
        <Flex justify="space-between">
          <Text fontWeight="bold" fontSize="xl" color="brand.primary.dark_1">
            Cadastrar novos formulários
          </Text>
        </Flex>
        <Formik
          innerRef={formikRef}
          initialValues={{
            dsForm: '',
            coObject: '',
            coPhase: '',
            coFormat: '',
            dsVolumeVersion: '',
            coExemplary: '',
          }}
          onSubmit={handleSubmit}
          validationSchema={yup.object().shape({
            dsForm: yup.string().required('Nome do formulário é obrigatório'),
            coObject: yup.string().required('Objeto é obrigatório'),
            coPhase: yup.string().required('Fase é obrigatória'),
            coFormat: yup.string().required('Formato é obrigatório'),
            dsVolumeVersion: yup.string().required('Versão é obrigatória'),
            coExemplary: yup.string().required('Exemplar é obrigatório'),
          })}
        >
          <Form>
            <Input
              name="dsForm"
              label="Nome do formulário"
              placeholder="Digite o nome..."
            />
            <Flex gap={4}>
              <Select
                name="coObject"
                label="Objeto"
                placeholder="Selecione uma opção"
                options={objectsOptions}
                isLoading={isFieldValuesLoading}
              />
              <Select
                name="coPhase"
                label="Fase"
                placeholder="Selecione uma opção"
                options={stageOptions}
                isLoading={isFieldValuesLoading}
              />
            </Flex>
            <Flex gap={4}>
              <Select
                name="coFormat"
                label="Formato do Volume"
                placeholder="Selecione uma opção"
                options={volumeFormatOptions}
                isLoading={isFieldValuesLoading}
              />
              <Select
                name="dsVolumeVersion"
                label="Versao do Volume"
                placeholder="Selecione uma opção"
                options={volumeVersionOptions}
                isLoading={isFieldValuesLoading}
              />
              <Select
                name="coExemplary"
                label="Exemplar do Volume"
                placeholder="Selecione uma opção"
                options={volumeExemplaryOptions}
                isLoading={isFieldValuesLoading}
              />
            </Flex>
          </Form>
        </Formik>
      </Stack>
      <Stack p={5} boxShadow="md" borderRadius="base" gap={4}>
        <Flex justify="space-between">
          <Text fontWeight="bold" fontSize="xl" color="brand.primary.dark_1">
            Perguntas
          </Text>
        </Flex>
        {questions.length === 0 ? (
          <EmptyQuestions />
        ) : (
          <QuestionsList questions={[]} />
        )}
        <Flex
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          flexGrow={1}
          padding="5"
          gap="8"
        >
          <Button
            size="sm"
            leftIcon={<BsChevronLeft />}
            variant="ghost"
            fontSize="sm"
            color="brand.primary.dark_1"
            onClick={() => navigate('/formularios/')}
          >
            Voltar
          </Button>
          <Flex gap={2}>
            <Button
              variant="outline"
              leftIcon={<FaPlusCircle />}
              onClick={handleAddQuestion}
            >
              Adicionar Pergunta
            </Button>
            <Button
              leftIcon={<FaSave />}
              // variant={questions.length === 0 ? 'disabled' : 'solid'}
              // disabled
              isLoading={isRegisteringForm}
              onClick={handleRegisterForm}
            >
              Cadastrar Formulário
            </Button>
          </Flex>
        </Flex>
      </Stack>
      <ModalQuestion isOpen={isModalOpen} onClose={onCloseModal} />
    </>
  )
}

export default CreateForm
