import React, { useCallback, useMemo, useState } from 'react'
import { FaFileImport, FaRegEdit } from 'react-icons/fa'
import { useNavigate } from 'react-router-dom'

import { SearchIcon } from '@chakra-ui/icons'
import {
  Box,
  Button,
  Flex,
  IconButton,
  Text,
  VStack,
  CircularProgress,
  useToast,
} from '@chakra-ui/react'

import { useQueryGetObjects } from '@/api/objects/queries'
import DataFilter, { IFilter } from '@/components/DataFilter'
import DataTable from '@/components/DataTable'
import { IHeader } from '@/components/DataTable/types'
import Pagination from '@/components/Pagination'
import Tag from '@/components/Tag'
import { statusObject } from '@/components/Tag/StatusObject/types'
import EmptyObjects from '@/pages/Objects/EmptyObjects'
import { reverseDate } from '@/utils/date'
import { getErrorDetails } from '@/utils/error'

const EditButton: React.FC<{ id: number }> = ({ id }) => {
  const navigate = useNavigate()
  const handleEdit = useCallback(
    () => navigate(`detalhes/${id}`),
    [id, navigate]
  )

  return (
    <IconButton
      aria-label="Editar objeto"
      variant="ghost"
      size="sm"
      icon={<FaRegEdit size="20px" />}
      color="brand.primary.dark_1"
      onClick={handleEdit}
    />
  )
}

const ManagementDetailsObjects: React.FC = () => {
  const navigate = useNavigate()
  const toast = useToast()
  const [filter, setFilter] = useState({
    search: '',
    startDate: '',
    endDate: '',
    stage: '',
  })
  const [pagination, setPagination] = useState({
    page: 0,
    pageSize: 10,
    itemsCount: 0,
    pageCount: 1,
    pageSizeOptions: [10, 20, 30, 40, 50],
  })

  const { data: objects, isLoading } = useQueryGetObjects(
    {
      coObjectDigitalPnld: filter.search,
      inicialDate: filter.startDate,
      finalDate: filter.endDate,
      dsStage: filter.stage,
    },
    {
      onError: error => {
        toast({
          title: 'Erro recebendo os dados, contate o suporte.',
          description: getErrorDetails(error),
          status: 'error',
          duration: 3000,
          isClosable: true,
        })
      },
    }
  )

  const randomStatus = useCallback((index: number) => {
    const statuses: statusObject[] = [
      'finished_red',
      'active',
      'inactive',
      'waiting_bond',
    ]
    return statuses[index % statuses.length]
  }, [])

  const handleImportData = useCallback(() => navigate('importar'), [navigate])

  const data = useMemo(() => {
    if (!objects) return []
    return objects.map((object, index) => ({
      id: object.coObject,
      objeto: (
        <VStack justify="flex-start">
          <Box w="full">
            <Text fontSize="sm" color="brand.neutral.dark_2" textAlign="start">
              {object.coObjectDigitalPnld || 'Não informado'}
            </Text>
            <Text fontSize="sm" color="brand.neutral.dark_2" textAlign="start">
              2024-2027
            </Text>
          </Box>
        </VStack>
      ),
      fase: object.dsStage || 'Não informado',
      colecoes: object.nuCollections.toString() || '0',
      volumes: object.nuVolumes.toString() || '0',
      status: <Tag group="statusObjects" value={randomStatus(index)} />,
      edit: <EditButton id={object.coObject} />,
    }))
  }, [objects, randomStatus])

  const headers: Array<IHeader> = useMemo(
    () => [
      { name: 'Objeto', key: 'objeto', align: 'center', type: 'element' },
      { name: 'Fase', key: 'fase', align: 'center' },
      { name: 'Quant. de coleções', key: 'colecoes', align: 'center' },
      { name: 'Quant. de volumes', key: 'volumes', align: 'center' },
      { name: 'Status', key: 'status', type: 'element', align: 'center' },
      { name: '', key: 'edit', type: 'element', align: 'center' },
    ],
    []
  )

  const filterOptions: Array<IFilter> = useMemo(
    () => [
      {
        name: 'stage',
        label: 'Fase',
        type: 'text',
        icon: <SearchIcon />,
      },
    ],
    []
  )

  const handleFilters = (filters: Record<string, string>) => {
    setFilter({
      search: filters.search || '',
      startDate: reverseDate(filters.startDate) || '',
      endDate: reverseDate(filters.endDate) || '',
      stage: filters.stage || '',
    })
  }

  const handlePaginationChange = useCallback(
    ({
      pageIndex,
      itemsPerPage,
    }: {
      pageIndex: number
      itemsPerPage: number
    }) => {
      setPagination(prev => ({
        ...prev,
        page: pageIndex,
        pageSize: itemsPerPage,
        pageCount: Math.ceil((objects?.length || 0) / itemsPerPage),
      }))
    },
    [objects]
  )

  const renderObjects = () => {
    if (isLoading) {
      return (
        <Flex
          justifyContent="center"
          alignItems="center"
          backgroundColor="white"
          flex={1}
          w="100%"
        >
          <VStack spacing={4}>
            <Text>Buscando dados dos objetos...</Text>
            <CircularProgress size="5rem" thickness="0.25rem" isIndeterminate />
          </VStack>
        </Flex>
      )
    }

    if (!objects || objects.length === 0) {
      return <EmptyObjects />
    }

    return (
      <Flex direction="column" flex={1}>
        <Box overflowX="auto" flex={1} py={4}>
          <DataTable data={data} headers={headers} />
        </Box>
        <Pagination
          pageIndex={pagination.page}
          optionsItemsPerPage={pagination.pageSizeOptions}
          itemsPerPage={pagination.pageSize}
          itemsCount={objects.length}
          pageCount={pagination.pageCount}
          onChangePageInfo={handlePaginationChange}
        />
      </Flex>
    )
  }

  return (
    <Flex direction="column" h="100%" w="100%" flexGrow={1}>
      {!isLoading && objects && objects.length > 0 && (
        <Flex
          direction="column"
          alignItems="center"
          justifyContent="center"
          borderRadius="md"
          boxShadow="md"
          p={4}
        >
          <DataFilter
            id="data-filter"
            filters={filterOptions}
            canDownload={false}
            onChangeFilter={handleFilters}
          />
        </Flex>
      )}
      <Flex
        borderRadius="md"
        boxShadow="md"
        direction="column"
        flex={1}
        w="100%"
        p={5}
        sx={{
          '@media (max-width: 600px)': {
            flexDirection: 'column',
            'th, td': { whiteSpace: 'nowrap' },
          },
        }}
      >
        <Flex
          justifyContent="space-between"
          alignItems="center"
          sx={{
            '@media (max-width: 500px)': {
              flexDirection: 'column',
              justifyContent: 'flex-start',
              alignItems: 'flex-start',
              'th, td': { whiteSpace: 'nowrap' },
            },
          }}
        >
          <Text
            fontSize="xl"
            color="brand.primary.dark_1"
            fontWeight="semibold"
          >
            Gestão de Objetos
          </Text>
          <Button
            leftIcon={<FaFileImport />}
            backgroundColor="brand.primary.dark_1"
            color="white"
            py={2}
            px={6}
            borderRadius="md"
            fontSize="md"
            fontWeight="medium"
            height="32px"
            _hover={{ bg: 'brand.primary.dark_2' }}
            onClick={handleImportData}
          >
            Importar Objetos
          </Button>
        </Flex>
        {renderObjects()}
      </Flex>
    </Flex>
  )
}

export default ManagementDetailsObjects
