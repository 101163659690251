import React, { useEffect, useMemo, useState } from 'react'
import { FaRegEdit, FaTrashAlt } from 'react-icons/fa'
import { useNavigate } from 'react-router-dom'

import {
  Stack,
  Text,
  Flex,
  IconButton,
  useToast,
  useDisclosure,
} from '@chakra-ui/react'

import { useMutationDeleteForm } from '@/api/forms/form/mutations'
import { useQueryForms } from '@/api/forms/form/queries'
import { useQueryGetObjects } from '@/api/objects/queries'
import { IPageInfoProps } from '@/components/Pagination/types'
import { getErrorDetails } from '@/utils/error'

import { ModalDeleteForm } from './DeleteFormModal'

interface IInfoProps {
  title: string
  subtitle: string
}

const Info = ({ title, subtitle }: IInfoProps) => {
  return (
    <Stack gap={0.5} color="brand.neutral.dark_2">
      <Text fontSize="sm">{title}</Text>
      <Text fontSize="xs">{subtitle}</Text>
    </Stack>
  )
}

interface ActionsProps {
  id: number
}

export const useChecklistController = () => {
  const {
    isOpen: isDeleteModalOpen,
    onClose: onDeleteModalClose,
    onOpen: onDeleteModalOpen,
  } = useDisclosure()

  const Actions = ({ id }: ActionsProps) => {
    const navigate = useNavigate()

    const handleEditChecklist = () => {
      navigate(`/formularios/formulario/detalhes/${id}`)
    }

    const handleDeleteChecklist = () => {
      setCoFormToDelete(id)
      onDeleteModalOpen()
    }

    return (
      <>
        <Flex justify="space-between">
          <IconButton
            variant="ghost"
            aria-label="Editar"
            icon={<FaRegEdit />}
            onClick={handleEditChecklist}
          />
          <IconButton
            variant="ghost"
            aria-label="Deletar"
            icon={<FaTrashAlt />}
            onClick={handleDeleteChecklist}
          />
        </Flex>
      </>
    )
  }

  const toast = useToast()
  const [pagination, setPagination] = useState({
    page: 1,
    pageSize: 10,
    itemsCount: 0,
    pageCount: 1,
    pageSizeOptions: [1, 10, 20, 30, 40, 50],
  })
  const [coFormToDelete, setCoFormToDelete] = useState<number | undefined>(
    undefined
  )
  const [filter, setFilter] = useState({
    search: '',
  })
  const [statusTab, setStatusTab] = useState('PENDENTE')
  const {
    data: forms,
    isFetching: isFormsFetching,
    refetch: refetchForms,
  } = useQueryForms(
    {
      page: pagination.page,
      pageSize: pagination.pageSize,
      stLink: statusTab,
    },
    {
      onSuccess: data => {
        setPagination({
          ...pagination,
          page: data.pagination.currentPage,
          pageSize: data.pagination.pageSize || 10,
          itemsCount: data.pagination.count,
          pageCount: data.pagination.totalPages,
        })
      },
      onError: error => {
        toast({
          status: 'error',
          title: 'Erro ao buscar formulários',
          description: 'Tente novamente mais tarde',
          isClosable: true,
        })
      },
    }
  )

  const formsTableData = useMemo(() => {
    return (forms?.results || []).map(form => {
      return {
        id: form.coForm,
        checklist: <Info title={form.dsForm} subtitle={String(form.coForm)} />,
        object: (
          <Info
            title={String(form.coObject)}
            subtitle={String(form.coObject)}
          />
        ),
        type: form?.tpForm,
        actions: <Actions id={form.coForm} />,
      }
    })
  }, [forms])

  const handleFilters = (filters: Record<string, string>) => {
    setFilter({
      search: filters.search || '',
    })
  }

  const handleOnChangePageInfo = ({
    pageIndex,
    itemsPerPage,
  }: IPageInfoProps) => {
    const previousItemsPerPage = pagination.pageSize
    const adjustedPageIndex =
      itemsPerPage !== previousItemsPerPage ? 1 : pageIndex + 1

    setPagination({
      ...pagination,
      page: adjustedPageIndex,
      pageSize: itemsPerPage,
    })
  }

  return {
    formsTableData,
    refetchForms,
    handleFilters,
    statusTab,
    setStatusTab,
    pagination,
    setPagination,
    isFormsFetching,
    handleOnChangePageInfo,
    coFormToDelete,
    isDeleteModalOpen,
    onDeleteModalClose,
  }
}
