import React from 'react'

import { Button, Flex, Stack, Text, useToast } from '@chakra-ui/react'

import { useMutationApproveForm } from '@/api/forms/form/mutations'
import Modal from '@/components/Modal'

import { ICustomApproveButtonProps } from './types'

export const ModalApproveForm = ({
  coForm,
  isOpen,
  onClose,
  handleApprove,
}: ICustomApproveButtonProps): JSX.Element => {
  const toast = useToast()
  const { mutate: approveForm, isLoading: isApproving } =
    useMutationApproveForm({
      onSuccess: () => {
        onClose()
        handleApprove()
        toast({
          title: 'Formulário aprovado com sucesso!',
          status: 'success',
          duration: 3000,
          isClosable: true,
        })
      },
    })

  return (
    <Modal
      title="Aprovar formulário"
      isOpen={isOpen}
      onClose={onClose}
      size="xl"
    >
      <Stack spacing="4">
        <Text fontSize="sm" color="brand.primary.dark_2">
          Tem certeza que deseja aprovar esse formulário e liberar para ser
          respondido?
        </Text>
        <Flex
          justifyContent="right"
          gap="12px"
          alignItems="center"
          flexDirection="row"
        >
          <Button
            variant="outline"
            borderRadius="full"
            isDisabled={isApproving}
            onClick={onClose}
          >
            Cancelar
          </Button>
          <Button
            borderRadius="full"
            isLoading={isApproving}
            onClick={() => {
              approveForm({
                coForm: Number(coForm),
                isApproved: true,
              })
            }}
          >
            Aprovar formulário
          </Button>
        </Flex>
      </Stack>
    </Modal>
  )
}
