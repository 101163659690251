import React, { useEffect, useMemo, useState } from 'react'
import { BsCheckCircle, BsXCircle } from 'react-icons/bs'
import { FaChevronLeft } from 'react-icons/fa'

import { Button, Text, Stack, useToast } from '@chakra-ui/react'

import { useMutationUnlinkUsers } from '@/api/user/management/mutations'
import Modal from '@/components/Modal'
import { getErrorDetails } from '@/utils/error'

import S from '../styles'
import { IUnlikModalProps } from './types'

const UnlinkModal: React.FC<IUnlikModalProps> = ({
  isOpen,
  onClose,
  users,
}) => {
  const toast = useToast()

  const { mutate: unlinkUsers, isLoading: isUnlinkLoading } =
    useMutationUnlinkUsers({
      onSuccess: () => {
        toast({
          title: 'Vínculo encerrado com sucesso',
          status: 'success',
          duration: 3000,
          isClosable: true,
        })
      },
      onError: error => {
        toast({
          title: 'Erro ao encerrar vínculo',
          description: getErrorDetails(error),
          status: 'error',
          duration: 3000,
          isClosable: true,
        })
      },
    })

  const handleUnlink = () => {
    const usersUnlink = users.map(user => (user as { co_user: number }).co_user)

    const payload = {
      coUser: usersUnlink,
    }

    unlinkUsers(payload)
  }

  return (
    <Modal
      key="modal-desvincular-em-lote"
      title="Desvincular Usuário"
      isOpen={isOpen}
      onClose={onClose}
      isCentered
      size="xl"
    >
      <Text fontSize="xs">
        Quando encerrarmos a vinculação de um usuário, ele não terá mais acesso
        aos objetos associados. No entanto, o perfil e o acesso às informações
        sobre as atividades desses usuários permanecerão disponíveis para
        consulta. Nesse sentido, pode-se vincular novamente o usuário caso um
        novo contrato seja estabelecido. <br />
        <br /> Você tem certeza de que deseja encerrar este vínculo?
      </Text>
      <S.ModalActionButtons>
        <Button
          key="go-back"
          size="sm"
          leftIcon={<BsXCircle />}
          color="white"
          bg="brand.error.base"
          _hover={{
            bg: 'brand.error.dark',
          }}
          isLoading={isUnlinkLoading}
          onClick={handleUnlink}
        >
          Sim, quero encerrar o vínculo
        </Button>
        <Button
          key="confirm"
          size="sm"
          leftIcon={<BsCheckCircle />}
          color="white"
          bg="brand.primary.dark_1"
          _hover={{
            bg: 'brand.primary.dark_2',
          }}
          isDisabled={isUnlinkLoading}
          onClick={onClose}
        >
          Não, mantenha o vínculo
        </Button>
      </S.ModalActionButtons>
    </Modal>
  )
}

export default UnlinkModal
